// vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Alert,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverBody,
  UncontrolledDropdown
} from "reactstrap";

// app
import SeasonSchedule from "./categories/SeasonSchedule";
import DateRangeFacet from "../../../ui/facet/DateRangeFacet";
import seasonReducer from "./seasonReducer";
import { ClubCourseContext } from "../ClubCourse";
import {
  SEASON_RATE_UPDATE,
  SEASON_RATE_CREATE,
  SEASON_RATE_DELETE
} from "../../../../common/Mutations";
import { LayoutContext } from "../../../app/Layout";
import Loader from "../../../ui/Loader";
import { changeRateDate, validDate } from "./SeasonHelper";
import SeasonRatePriceCollection from "./collections/SeasonRatePriceCollection";
import DayTypesModal from "./editModal/DayTypesModal";
import { Days } from "../../../../common/Days";

// context for child components
/** @type { SeasonContext } */
export const SeasonContext = React.createContext(null);

function Season(props) {
  function getSeasonTypeLabel(season) {
    const item = courseState.seasonTypes.find(
      (item) => item.type === season.type
    );
    return item ? item.name : "Select a season";
  }

  function handleDateChange(dates) {
    // only update dates when both have changed
    if (dates.startDate && dates.endDate) {
      const startDate = dates.startDate.format();
      const endDate = dates.endDate.format();
      dispatch({ type: "SET_DATES", payload: { startDate, endDate } });
    }
  }

  function handleDelete() {
    deleteSeasonRate({
      variables: { courseId: courseState._id, seasonRateId: season._id }
    });
  }

  function filterRatePrice(ratePrice, prop) {
    return (
      ratePrice[prop] !== undefined &&
      ratePrice[prop] !== null &&
      !isNaN(ratePrice[prop])
    );
  }

  function handleSubmit() {
    if (saveBtnDisabled) {
      return addAlert({
        color: "danger",
        message: "Error while trying to save season."
      });
    }
    const seasonRateId = season._id;
    const courseId = courseState._id;
    // strip input from any rate prices that don't have required fields
    let input = {
      ...season,
      startDate: moment(season.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(season.endDate).format("YYYY-MM-DD HH:mm:ss"),
      rates: season.rates.map((rate) => ({
        ...rate,
        startDate: changeRateDate(rate.startDate, season.startDate),
        endDate: changeRateDate(rate.endDate, season.endDate),
        prices: rate.prices
          .filter((price) => filterRatePrice(price, "price"))
          .filter((price) => filterRatePrice(price, "rackPrice"))
          .filter((price) => filterRatePrice(price, "multiplier"))
      })),
      weekendDays: days ?? [6, 0]
    };

    if (seasonRateId) {
      delete input._id;
      updateSeasonRate({ variables: { input, courseId, seasonRateId } });
    } else {
      createSeasonRate({ variables: { input, courseId } });
    }
  }

  function handleCreateSuccess(res) {
    toggle();
    courseDispatch(
      {
        type: "SEASON_CREATE",
        payload: res.createSeasonRate.seasonRate
      },
      false
    );
    addAlert({ color: "success", message: "Season successfully created" });
  }

  function handleFail(message) {
    var element = document.getElementById("modal");
    element.scrollIntoView();
    setError(message);
  }

  function handleUpdateSuccess(res) {
    toggle();
    if (res.updateSeasonRate.ok) {
      courseDispatch(
        {
          type: "SEASON_UPDATE",
          payload: res.updateSeasonRate.seasonRate
        },
        false
      );
      addAlert({ color: "success", message: "Season successfully updated" });
    } else {
      addAlert({ color: "danger", message: "Season update failed" });
    }
  }

  function handleDeleteSuccess() {
    togglePopover();
    toggle();
    courseDispatch({ type: "SEASON_DELETE", payload: season._id }, false);
    addAlert({ color: "success", message: "Season successfully deleted" });
  }

  function toggle() {
    setPopover(false);
    props.onClose();
  }

  function togglePopover() {
    setPopover(!popoverOpen);
  }
  function toggleDayTypesModal() {
    setEditingDayTypes(!editingDayTypes);
  }

  // state and dispatch method from parents
  const { state: courseState, dispatch: courseDispatch } =
    useContext(ClubCourseContext);
  const { addAlert } = useContext(LayoutContext);

  // state and dispatch method for scope and children
  const [season, dispatch] = React.useReducer(seasonReducer, null);

  // State hooks
  const [popoverOpen, setPopover] = useState(false);
  const [editingDayTypes, setEditingDayTypes] = useState(false);
  const [editingStandard, setEditingStandard] = useState(false);
  const [editingWeekend, setEditingWeekend] = useState(false);
  const [error, setError] = useState(false);
  const [days, setDays] = useState(() => {
    if (props.season && props.season.weekendDays) {
      return Array.isArray(props.season.weekendDays)
        ? [...props.season.weekendDays]
        : Object.values(props.season.weekendDays);
    }
    return [0, 6];
  });

  useEffect(() => {
    if (season && season.weekendDays) {
      const newDays = Array.isArray(season.weekendDays)
        ? [...season.weekendDays]
        : Object.values(season.weekendDays);
      setDays(newDays);
    }
  }, [season]);

  // mutations
  const [updateSeasonRate, { loading: updateLoading }] = useMutation(
    SEASON_RATE_UPDATE,
    {
      onCompleted: handleUpdateSuccess,
      onError: (message) => {
        handleFail("Failed to update season rate.");
      }
    }
  );
  const [createSeasonRate, { loading: createLoading }] = useMutation(
    SEASON_RATE_CREATE,
    {
      onCompleted: handleCreateSuccess,
      onError: () => handleFail("Failed to create season rate.")
    }
  );
  const [deleteSeasonRate, { loading: deleteLoading }] = useMutation(
    SEASON_RATE_DELETE,
    {
      onCompleted: handleDeleteSuccess,
      onError: () => handleFail("Failed to delete season rate.")
    }
  );

  React.useEffect(() => {
    setError(false);

    if (props.open) {
      // init season from props when modal opens/closes
      dispatch({
        type: "INIT",
        // create a completely new object with no references
        // so that changes doesn't persist between closing and
        // opening the modal for a season
        payload: JSON.parse(JSON.stringify(props.season))
      });
    }
  }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

  // guard
  if (!season || !season.rates) return null;
  // shortcut
  const { seasonType } = season;
  // boolean to determine if the save button should be disabled
  const saveBtnDisabled =
    editingStandard ||
    editingWeekend ||
    !season.endDate ||
    !season.seasonType ||
    season.seasonType.type === "";

  return (
    <SeasonContext.Provider
      value={{
        season,
        dispatch
      }}
    >
      <Modal isOpen={props.open} toggle={toggle} id="modal" className="Season">
        <ModalHeader toggle={toggle}>
          {season._id ? "Edit Season" : "Create New Season"}
        </ModalHeader>
        <ModalBody>
          {(createLoading || updateLoading || deleteLoading) && (
            <Loader fullscreen />
          )}
          {error && <Alert color="danger">{error}</Alert>}
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between w-100">
              <DayTypesModal
                isOpen={editingDayTypes}
                toggle={toggleDayTypesModal}
                onClose={() => setEditingDayTypes(false)}
              />
              <div className="d-flex align-items-start">
                <div>
                  <label>Season type</label>
                  <UncontrolledDropdown className="d-inline-block mr-2">
                    <DropdownToggle
                      color={seasonType ? "primary" : "outline-light"}
                      caret
                    >
                      {getSeasonTypeLabel(seasonType)}
                    </DropdownToggle>
                    <DropdownMenu>
                      {courseState.seasonTypes.map((item) => (
                        <DropdownItem
                          key={item._id}
                          active={seasonType === item.type}
                          onClick={() => {
                            dispatch({
                              type: "SET_SEASON_TYPE",
                              payload: item
                            });
                          }}
                        >
                          {item.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className="position-relative" style={{ zIndex: 4 }}>
                  <label>Season dates</label>
                  <DateRangeFacet
                    enableDatesOutsideRange
                    showCalendarIcon
                    small
                    startDate={season?.startDate && moment(season.startDate)}
                    endDate={season?.endDate && moment(season.endDate)}
                    id="season-span"
                    startDateId="season-span-start-date"
                    endDateId="season-span-end-date"
                    dateValidation
                    excludeSeason={season?._id}
                    addingSeason={season?._id ? true : false}
                    season_dates_occupied={props.season_dates_occupied}
                    onDatesChange={handleDateChange}
                    // always validate end date for overlapping with seasons
                    isOutsideRange={(day) => {
                      // validateDate returns boolean if day is outside of range
                      return !validDate(
                        day,
                        props.season_dates_occupied,
                        season._id
                      );
                    }}
                    isDayBlocked={() => false}
                    addAlert={addAlert}
                  />
                </div>
              </div>
              <Button
                color="link"
                className="text-warning shadow-none"
                onClick={toggleDayTypesModal}
              >
                Edit Day types
              </Button>
            </div>
          </div>
          <SeasonSchedule
            toggleEdit={(value) => setEditingStandard(value)}
            scheduleType="STANDARD"
          />
          <SeasonSchedule
            toggleEdit={(value) => setEditingWeekend(value)}
            scheduleType="WEEKEND"
            days={Days}
            selectedDays={days}
            setDays={setDays}
            season={season}
          />
        </ModalBody>
        <ModalFooter>
          {season._id && (
            <>
              <Button
                color="link"
                className="text-danger"
                onClick={togglePopover}
                id="popover-season"
              >
                <FontAwesomeIcon icon="trash-alt" className="mr-1" />
                Delete season
              </Button>
              <Popover
                className="p-3"
                placement="top"
                style={{ maxWidth: "200px" }}
                isOpen={popoverOpen}
                target="popover-season"
                toggle={togglePopover}
              >
                <PopoverBody>
                  Are you sure?
                  <Button
                    className="ml-2"
                    color="danger"
                    size="sm"
                    onClick={handleDelete}
                  >
                    Yes
                  </Button>
                </PopoverBody>
              </Popover>
              <Button color="light" outline onClick={props.onDuplicate}>
                <FontAwesomeIcon icon="copy" className="mr-1" />
                Duplicate season
              </Button>
            </>
          )}
          <Button
            color="secondary"
            onClick={handleSubmit}
            disabled={saveBtnDisabled}
          >
            Save season
          </Button>
        </ModalFooter>
      </Modal>
    </SeasonContext.Provider>
  );
}

export const SeasonPropTypes = {
  _id: PropTypes.string,
  seasonType: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string
  }),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      buggyIncluded: PropTypes.bool,
      scheduleType: PropTypes.oneOf(["STANDARD", "WEEKEND"]),
      dayType: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string
      }),
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.number,
          rackPrice: PropTypes.number,
          modifier: PropTypes.number,
          rateType: PropTypes.oneOf(
            SeasonRatePriceCollection.map((rp) => rp.rateType)
          ),
          targetRateId: PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          ),
          useTeeSheetPricing: PropTypes.bool
        })
      )
    })
  )
};

Season.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  season: PropTypes.shape(SeasonPropTypes)
};

Season.defaultProps = {
  endDate: moment(),
  startDate: moment()
};

export default Season;
