import React, { useState, useRef, useContext } from "react";
import { Col, Label, Popover, PopoverBody, Button } from "reactstrap";
import Select from "react-select";
import useClickOutside from "../../../hooks/useClickOutside";
import { useMutation } from "@apollo/client";
import {
  FUNDS_TRANSFER_MARK,
  SYNC_CURRENCIES
} from "../../../common/Mutations";
import { LayoutContext } from "../../app/Layout";
import URLHelper from "../../../common/URLHelper";
import { useHistory } from "react-router-dom";
import {
  InvoiceStatusHelper,
  TRANSFERED_OPTIONS
} from "../../../common/InvoiceStatusHelper";
import Loader from "../../ui/Loader";
import { openDocumentInNewWindow } from "../../../common/DocumentHelper";

const filteredOptions = TRANSFERED_OPTIONS.filter((option) => {
  return !(option.value === "settled" || option.value === "kawaii");
});

const MarkAsTransferredSelect = ({
  buildFilter,
  disabled,
  isOfficialDocumentMode,
  currencies,
  refetchCurrencies
}) => {
  const { addAlert } = useContext(LayoutContext);

  const [value, setValue] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [syncCurrenciesPopoverOpen, setSyncCurrenciesPopoverOpen] =
    useState(false);
  const popoverRef = useRef(null);
  const currenciesPopoverRef = useRef(null);
  const inputRef = useRef(null);
  const history = useHistory();

  const handleOptionChange = (option) => {
    setValue(option.value);
    setPopoverOpen(true);
  };

  const checkCurrenciesOutdated = () => {
    const currency = currencies[0];
    if (!currency) return;
    if (!currency.updatedAt) return true;
    const updatedAt = new Date(currency.updatedAt);
    const now = new Date();

    const hoursPassed = (now - updatedAt) / (1000 * 60 * 60);
    return hoursPassed >= 12;
  };

  const handleFundsTransfer = () => {
    const urlQueryParams = URLHelper.queryToState(history.location);
    if (Object.keys(urlQueryParams).length > 0) {
      fundsTransfer({
        variables: {
          filter: buildFilter(),
          fundsTransferred: value
        }
      });
    } else
      addAlert({
        color: "danger",
        message:
          "Please ensure that a filter is selected before marking the statuses as transferred, not transferred, or settled.s"
      });
  };

  const handleConfirm = () => {
    setPopoverOpen(false);
    if (checkCurrenciesOutdated()) {
      setSyncCurrenciesPopoverOpen(true);
    } else handleFundsTransfer();
  };

  const [SyncCurrencies] = useMutation(SYNC_CURRENCIES, {
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      if (result.ok) {
        refetchCurrencies();
      }
    }
  });
  const handleConfirmSyncCurrencies = async () => {
    setSyncCurrenciesPopoverOpen(false);
    const result = await SyncCurrencies();
    if (result?.data?.syncCurrencies.ok) {
      handleFundsTransfer();
    } else {
      addAlert({
        color: "danger",
        message: result?.data?.syncCurrencies.returnMessage
      });
    }
  };

  const handleCancelSyncCurrencies = () => {
    setSyncCurrenciesPopoverOpen(false);
    handleFundsTransfer();
  };

  const handleCancel = () => {
    setPopoverOpen(false);
    setValue(null);
  };

  // if user clicks on any of the refs
  useClickOutside([popoverRef, currenciesPopoverRef, inputRef], () =>
    handleCancel()
  );
  useClickOutside([currenciesPopoverRef, popoverRef, inputRef], () => {
    setSyncCurrenciesPopoverOpen(false);
  });

  const onMarkAsTransferredCompleted = ({ fundsTransferMark }) => {
    if (fundsTransferMark.ok) {
      addAlert({
        color: "success",
        message: fundsTransferMark.returnMessage
      });
      if (fundsTransferMark.file) {
        openDocumentInNewWindow(
          fundsTransferMark.file.encoding,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      }

      return setTimeout(() => {
        window.location.reload(true);
      }, 1500);
    }
    addAlert({
      color: "danger",
      message:
        fundsTransferMark.returnMessage ||
        "Error while trying to change statuseas"
    });
  };

  // mutation
  const [fundsTransfer, { loading }] = useMutation(FUNDS_TRANSFER_MARK, {
    onCompleted: onMarkAsTransferredCompleted,
    onError(error) {
      addAlert({
        color: "danger",
        message: "Error while trying to change statuses"
      });
    }
  });
  return (
    <Col lg={3} md={4} sm={4} xs={5}>
      <Label htmlFor="mark-as-transferred-select">Mark as </Label>
      {loading ? (
        <Loader size={30} />
      ) : (
        <div ref={inputRef}>
          <Select
            id="mark-as-transferred-select"
            onChange={handleOptionChange}
            isDisabled={disabled}
            value={InvoiceStatusHelper.findTransferredOption(value) ?? null}
            options={filteredOptions.filter((option) => {
              if (
                (!isOfficialDocumentMode && option.value === "transferred") ||
                (isOfficialDocumentMode && option.value === "notTransferred")
              ) {
                return null;
              }
              return {
                label: option.label,
                value: option.value
              };
            })}
            style={{ minWidth: "240px" }}
          />
          <Popover
            isOpen={popoverOpen}
            innerRef={popoverRef}
            placement="bottom"
            target={inputRef}
          >
            <PopoverBody>
              Are you sure?
              <Button
                className="ml-2"
                color="danger"
                size="sm"
                onClick={handleConfirm}
              >
                Yes
              </Button>
              <Button
                className="ml-2"
                color="secondary"
                size="sm"
                onClick={handleCancel}
              >
                No
              </Button>
            </PopoverBody>
          </Popover>
          <Popover
            isOpen={syncCurrenciesPopoverOpen}
            innerRef={currenciesPopoverRef}
            placement="bottom"
            target={inputRef}
          >
            <PopoverBody>
              Currencies are outdated, would you like to sync them?
              <Button
                className="ml-2"
                color="danger"
                size="sm"
                onClick={handleConfirmSyncCurrencies}
              >
                Yes
              </Button>
              <Button
                className="ml-2"
                color="secondary"
                size="sm"
                onClick={handleCancelSyncCurrencies}
              >
                Use current
              </Button>
            </PopoverBody>
          </Popover>
        </div>
      )}
    </Col>
  );
};

export default MarkAsTransferredSelect;
