import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput
} from "availity-reactstrap-validation";
import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table
} from "reactstrap";
import CurrencySelect from "../../ui/CurrencySelect";
import { useMutation } from "@apollo/client";
import {
  CREATE_CURRENCY,
  SYNC_CURRENCIES,
  UPDATE_CURRENCY
} from "../../../common/Mutations";
import { LayoutContext } from "../../app/Layout";
import Loader from "../../ui/Loader";

const CurrencyModal = ({ isOpen, toggle, currencies, refetchCurrencies }) => {
  const { addAlert } = useContext(LayoutContext);
  const [showEditInput, setshowEditInput] = useState(false);
  const [showAddNewInput, setshowAddNewInput] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [syncingCurrencies, setSyncingCurrencies] = useState(false);

  const handleAddSubmit = (event, values) => {
    CreateCurrency({
      variables: {
        input: {
          name: values.name,
          value: +values.value
        }
      }
    });
  };

  const handleEditSubmit = (event, values) => {
    UpdateCurrency({
      variables: {
        input: {
          name: selectedCurrency.name,
          value: +values.value
        }
      }
    });
  };

  // query

  // mutation
  const [CreateCurrency] = useMutation(CREATE_CURRENCY, {
    onCompleted: (data) => {
      addAlert({
        color: "success",
        message: "Currency added successfully!"
      });
      setSelectedCurrency({});
      setshowAddNewInput(false);
      refetchCurrencies();
    }
  });

  const [UpdateCurrency] = useMutation(UPDATE_CURRENCY, {
    onCompleted: () => {
      addAlert({
        color: "success",
        message: "Currency updated successfully!"
      });
      setSelectedCurrency({});
      setshowEditInput(false);
      refetchCurrencies();
    }
  });

  const [SyncCurrencies] = useMutation(SYNC_CURRENCIES, {
    onCompleted: (data) => {
      if (data.syncCurrencies.ok) {
        setSyncingCurrencies(false);
        refetchCurrencies();
        addAlert({
          color: "success",
          message: "Currencies synced successfully!"
        });
      } else {
        setSyncingCurrencies(false);
        addAlert({
          color: "danger",
          message: data.syncCurrencies.returnMessage
        });
      }
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="SubInvoiceModal"
      onClosed={() => {
        setshowEditInput(false);
        setshowAddNewInput(false);
        setSelectedCurrency(null);
      }}
    >
      <ModalHeader toggle={toggle}>Currency Modal</ModalHeader>
      <ModalBody>
        <Button
          onClick={() => {
            setshowAddNewInput(true);
            setshowEditInput(false);
            setSelectedCurrency(null);
          }}
        >
          Add new currency
        </Button>
        <Button
          className="ml-2 w-3/4"
          color="primary"
          disabled={syncingCurrencies}
          onClick={() => {
            setSyncingCurrencies(true);
            SyncCurrencies();
          }}
        >
          {syncingCurrencies ? "Syncing..." : "Sync currencies"}
        </Button>
        <Table striped>
          <thead>
            <tr>
              <th>Currency</th>
              <th>Value</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currencies?.map((currency) => {
              return (
                <tr key={currency.name}>
                  <td>{currency.name}</td>
                  <td>{currency.value}</td>
                  <td>
                    <Button
                      onClick={() => {
                        setshowEditInput(true);
                        setshowAddNewInput(false);
                        setSelectedCurrency(currency);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {showAddNewInput && (
          <AvForm onValidSubmit={handleAddSubmit}>
            <CurrencySelect
              onChange={(value) => {
                setSelectedCurrency({
                  ...selectedCurrency,
                  name: value
                });
              }}
            />
            <AvGroup>
              <Label>Currency</Label>
              <div className="d-flex align-items-end">
                <AvInput name="value" className="w-75" required />
                <AvInput
                  className="font-weight-bold ml-2 w-25"
                  disabled
                  name="name"
                  value={selectedCurrency?.name || ""}
                />
              </div>
              <AvFeedback>Please enter value!</AvFeedback>
            </AvGroup>

            <Button className="mt-3" color="primary">
              Submit values
            </Button>
          </AvForm>
        )}
        {showEditInput && (
          <AvForm onValidSubmit={handleEditSubmit}>
            <AvGroup>
              <Label>Currency</Label>
              <div className="d-flex align-items-end">
                <AvInput
                  name="value"
                  className="w-75"
                  required
                  value={selectedCurrency?.value || ""}
                />
                <AvInput
                  className="font-weight-bold ml-2 w-25"
                  disabled
                  name="name"
                  value={selectedCurrency?.name || ""}
                />
              </div>
              <AvFeedback>Please enter value!</AvFeedback>
            </AvGroup>
            <Button className="mt-3" color="primary">
              Submit values
            </Button>
          </AvForm>
        )}
        Last sync:{" "}
        {(currencies?.[0]?.updatedAt && (
          <strong>
            {new Date(currencies?.[0]?.updatedAt).toLocaleString()}
          </strong>
        )) || <strong>N/A</strong>}
      </ModalBody>
    </Modal>
  );
};

export default CurrencyModal;
