// Vendor
import React, { useContext, useState, useEffect } from "react";
import { Col } from "reactstrap";

// App
import { InputField } from "../../../ui/FunctionalComponents";
import { ClubCourseContext } from "../ClubCourse";
import { KeyValueStoreHelper } from "../../../../common/KeyValueStoreHelper";
import TemplatesSelect from "../../../ui/TemplatesSelect";

export function CourseKeyValueStore({ teeSheetProvider }) {
  const { state, dispatch } = useContext(ClubCourseContext);
  const { keyValueStore: courseKeyValueStore } = state;
  const [keyValueStore, setKeyValueStore] = useState({});

  useEffect(() => {
    let newKvs =
      KeyValueStoreHelper.getCourseKeyValueStoreFields(teeSheetProvider);
    if (courseKeyValueStore) {
      courseKeyValueStore.forEach((kv) => (newKvs[kv.key] = kv.value));
    }

    setKeyValueStore(newKvs);
  }, [courseKeyValueStore, teeSheetProvider]);

  /**
   * @param { { key: string, value: string }} payload
   */
  function handleDispatch(payload) {
    dispatch({ type: "KEY_VALUE_STORE", payload });
  }

  if (teeSheetProvider === "GOLF_MANAGER") {
    return (
      <Col>
        <InputField
          targetKey="ID_RESOURCE_TYPE"
          label="ID Resource Type"
          required={false}
          value={keyValueStore["ID_RESOURCE_TYPE"]}
          onChange={handleDispatch}
        />
      </Col>
    );
  } else if (teeSheetProvider === "ZEST") {
    return (
      <Col>
        <InputField
          targetKey="PROVIDER_COURSE_NAME"
          label="API Course name"
          required={false}
          value={keyValueStore["PROVIDER_COURSE_NAME"]}
          onChange={handleDispatch}
        />
      </Col>
    );
  } else if (teeSheetProvider === "GF365_SIMPLE") {
    return (
      <>
        <Col>
          <InputField
            className="TemplateSelect"
            targetKey="SCRAPER_URL"
            label="Scraper URL"
            required={false}
            value={keyValueStore["SCRAPER_URL"]}
            onChange={handleDispatch}
          />
        </Col>
        <Col>
          <TemplatesSelect
            onChange={(template) =>
              handleDispatch({
                key: "TEMPLATE",
                value: template ? template.label : ""
              })
            }
            defaultValue={
              keyValueStore["TEMPLATE"]
                ? {
                    label: keyValueStore["TEMPLATE"],
                    value: keyValueStore["TEMPLATE"]
                  }
                : null
            }
          />
        </Col>
        {keyValueStore["TEMPLATE"] === "TeeitUpTemplate" && (
          <Col>
            <InputField
              targetKey="SCRAPER_ALIAS"
              label="Scraper Alias"
              required={false}
              value={keyValueStore["SCRAPER_ALIAS"]}
              onChange={handleDispatch}
            />
          </Col>
        )}
      </>
    );
  }

  return null;
}
