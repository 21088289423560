export const Days = [
  {
    id: 1,
    day: "Monday",
    value: 1
  },
  {
    id: 2,
    day: "Tuesday",
    value: 2
  },
  {
    id: 3,
    day: "Wednesday",
    value: 3
  },
  {
    id: 4,
    day: "Thursday",
    value: 4
  },
  {
    id: 5,
    day: "Friday",
    value: 5
  },
  {
    id: 6,
    day: "Saturday",
    value: 6
  },
  {
    id: 7,
    day: "Sunday",
    value: 0
  }
];
