import { useMutation } from "@apollo/client";
import moment from "moment-timezone";
import React, { useContext, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input
} from "reactstrap";

import {
  CALCULATE_PRICE,
  SEND_ORDER_CANCELLATION_EMAIL,
  UPDATE_ORDER
} from "../../../common/Mutations";
import CardRow from "../../ui/CardRow";
import { OrderContext } from "../Order";
import { HandleOfflineOrderButton } from "./HandleOfflineOrderButton";
import { InternalCancellation } from "./InternalCancellation";
import { OrderSendEmailButton } from "./OrderSendEmailButton";
import { ProviderCancellationButton } from "./ProviderCancellationButton";
import { BookingStatus } from "../collections/OfflineBookingStatus";
import { LayoutContext } from "../../app/Layout";

/**
 * @param { Object } props
 * @param { OrderTeeTime } props.teetime
 * @param { string } props.clubBookingId
 * @param { string } props.localTimeZone
 * @param { string } props.provider
 * @param { number } props.index
 */
export function TeeTimeDetails({
  teetime,
  clubBookingId,
  localTimeZone,
  provider,
  index
}) {
  const { dispatch } = useContext(OrderContext);
  const { addAlert } = useContext(LayoutContext);
  const {
    state: {
      formatted,
      raw: { order, offlineBookings },
      previewLog,
      logs
    }
  } = useContext(OrderContext);
  const [hasCalculated, setHasCalculated] = useState(false);
  const [modal, setModal] = useState(false);

  const offlineBookingStatus =
    offlineBookings.find((o) => o._id === teetime.teeSheetOrderReference)
      ?.bookingStatus ?? "NA";

  const [updateOrder, { loading: loadingSavingOrder }] = useMutation(
    UPDATE_ORDER,
    {
      onCompleted: (response) => {
        if (response.orderUpdate.ok) {
          setModal(false);
          dispatch({
            type: "UPDATE_ORDER"
          });
          dispatch({ type: "TRIGGER_REFETCH" });
        }
      },
      onError: (err) => {
        console.error("Error updating order:", err);
        addAlert({
          color: "danger",
          message: "Failed to update the order. Please try again."
        });
      }
    }
  );

  const [sendCancellationConfirmation, { data, loading, error }] = useMutation(
    SEND_ORDER_CANCELLATION_EMAIL
  );

  const [calculatePrice] = useMutation(CALCULATE_PRICE, {
    onCompleted: (data) => {
      const { orderTotal, club, orderPayNowTotal } = data.calculatePrice;

      setModalValues((prev) => ({
        ...prev,
        orderTotal,
        orderPayNowTotal,
        clubTotal: club.total,
        teetimePrice: club.teetime.price,
        refundableAmount: club.teetime.refundableAmount,
        clubPayNowTotal: club.payNowTotal
      }));
      setHasCalculated(true);
    },
    onError: (err) => {
      console.error("Mutation error:", err);
      addAlert({
        color: "danger",
        message: "Failed to calculate the price. Please try again."
      });
    }
  });

  const getFieldPreview = (field) => {
    const clubIndex = order.clubs.findIndex((c) => c._id === clubBookingId);
    if (clubIndex === -1) return null;

    const teeTimeIndex = order.clubs[clubIndex].teetimes.findIndex(
      (tt) => tt._id === teetime._id
    );

    return (
      previewLog &&
      previewLog.changes.find(
        (change) =>
          change.path.length === 5 && // Ensure the path is exactly 5 levels deep
          change.path[0] === "clubs" &&
          change.path[1] === clubIndex &&
          change.path[2] === "teetimes" &&
          change.path[3] === teeTimeIndex &&
          change.path[4] === field
      )
    );
  };

  const priceFieldPreview = getFieldPreview("price");
  const refundableAmountFieldPreview = getFieldPreview("refundableAmount");
  const feeFieldPreview = getFieldPreview("fee");
  const bookingStatusFieldPreview = getFieldPreview("cancelled");

  const course = formatted.courses.find((course) =>
    course.teetimes.some((tt) => tt._id === teetime._id)
  );

  const [modalValues, setModalValues] = useState({});

  const toggle = () => {
    if (!modal) {
      setModalValues({
        orderTotal: formatted.total,
        clubTotal: course.total ?? 0,
        teetimePrice: teetime.price,
        orderPayNowTotal: formatted.payNowTotal,
        clubPayNowTotal: course.payNowTotal ?? 0,
        teetimePayNowTotal: teetime.payNowTotal,
        refundableAmount: teetime.refundableAmount ?? 0,
        fee: teetime.fee ?? 0
      });
    }

    setModal(!modal);
    setHasCalculated(false);
  };

  useEffect(() => {
    if (modal) {
      setModalValues({
        orderTotal: formatted.total,
        clubTotal: course.total ?? 0,
        teetimePrice: teetime.price,
        orderPayNowTotal: formatted.payNowTotal,
        clubPayNowTotal: course.payNowTotal ?? 0,
        teetimePayNowTotal: teetime.payNowTotal,
        refundableAmount: teetime.refundableAmount ?? 0,
        fee: teetime.fee ?? 0
      });
    }
  }, [formatted, modal, course, teetime]);

  const toggleCalculatePrice = () => {
    calculatePrice({
      variables: {
        orderId: formatted._id ?? null,
        teetimeId: teetime._id,
        teeTimePrice: modalValues.teetimePrice,
        fee: modalValues.fee
      }
    });
  };

  const handleModalChange = (field, value) => {
    setModalValues((prev) => ({
      ...prev,
      [field]: parseFloat(value)
    }));
  };

  const isAfterCancelDate = moment(teetime.cancelDateTime).isBefore();
  const hasErrors = teetime.bookingErrors?.length > 0;
  const canCancelProvider =
    !teetime.cancelled && !isAfterCancelDate && !hasErrors;
  const canCancelInternal = !teetime.cancelled;

  function handleSendCancellationEmail(email) {
    sendCancellationConfirmation({
      variables: {
        orderId: formatted._id ?? null,
        teetimeId: teetime._id,
        targetEmail: email
      }
    });
  }

  const handleUpdateOrder = () => {
    updateOrder({
      variables: {
        order: {
          _id: formatted._id,
          ...(modalValues.orderTotal && { total: modalValues.orderTotal }),
          ...(modalValues.orderPayNowTotal && {
            payNowTotal: modalValues.orderPayNowTotal
          }),
          clubs: [
            {
              _id: clubBookingId,
              ...(modalValues.clubTotal !== undefined && {
                total: modalValues.clubTotal
              }),
              ...(modalValues.clubPayNowTotal !== undefined && {
                payNowTotal: modalValues.clubPayNowTotal
              }),
              teetimes: [
                {
                  _id: teetime._id,
                  ...(modalValues.teetimePrice !== undefined && {
                    price: modalValues.teetimePrice
                  }),
                  ...(modalValues.teetimePayNowTotal !== undefined && {
                    payNowTotal: modalValues.teetimePayNowTotal
                  }),
                  ...(modalValues.refundableAmount !== undefined && {
                    refundableAmount: modalValues.refundableAmount
                  }),
                  ...(modalValues.fee !== undefined && { fee: modalValues.fee })
                }
              ]
            }
          ]
        }
      }
    });
  };

  const getHighestOldPrice = () => {
    const clubIndex = order.clubs.findIndex((c) => c._id === clubBookingId);
    if (clubIndex === -1) return null;

    const teeTimeIndex = order.clubs[clubIndex].teetimes.findIndex(
      (tt) => tt._id === teetime._id
    );

    const allChanges = logs.flatMap((log) => log.changes);

    const largestTeeTimeOldValue = allChanges
      .filter(
        (change) =>
          change.path[0] === "clubs" &&
          change.path[1] === clubIndex &&
          change.path[2] === "teetimes" &&
          change.path[3] === teeTimeIndex &&
          change.path[4] === "price"
      )
      .reduce((max, change) => Math.max(max, change.oldValue), -Infinity);

    if (largestTeeTimeOldValue === -Infinity) {
      return null;
    } else {
      return largestTeeTimeOldValue;
    }
  };

  const ErrorLabel = () => {
    let errorStatus = "ERROR";
    const anyErr = teetime.bookingErrors?.length > 0;
    if (!anyErr) return null;

    if (teetime.bookingErrors.length === 0) errorStatus = "PARTIAL";

    const textColor = errorStatus === "ERROR" ? "danger" : "warning";
    const text = errorStatus === "ERROR" ? "Failed" : "Partially failed";

    return (
      <p
        className={`text-uppercase ml-1 font-weight-bold text-${textColor} mb-0`}
      >
        {text}
      </p>
    );
  };

  return (
    <Card className="border-0 mb-4">
      <CardBody
        className="pb-3 py-0"
        style={{
          borderBottom:
            course.teetimes.length > 1 && index < course.teetimes.length - 1
              ? "1px solid #ccc"
              : "none",
          paddingBottom: "1.5rem"
        }}
      >
        <Row>
          <Col xs={12} sm={12} lg={10}>
            <Row className="align-items-center">
              <Col xs={12} sm={4}>
                <CardTitle className="mb-2">
                  <p className="font-weight-bold mb-1">Play Date</p>
                  <span>
                    {moment
                      .utc(teetime.playDate)
                      .tz(localTimeZone)
                      .format("YYYY-MM-DD HH:mm")}
                  </span>
                </CardTitle>
              </Col>
              <Col xs={12} sm={4}>
                <CardRow
                  label="Price"
                  value={priceFieldPreview?.newValue ?? teetime.price}
                  customValueStyles={
                    priceFieldPreview
                      ? {
                          backgroundColor: "#3dbfbe",
                          color: "white"
                        }
                      : undefined
                  }
                />
              </Col>

              <Col xs={12} sm={4}>
                <CardRow
                  label="Buggies"
                  value={
                    teetime.buggyIncluded ? "Buggy included" : teetime.buggies
                  }
                  valueClass={
                    teetime.buggyIncluded ? "text-success" : undefined
                  }
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} sm={4}>
                <CardRow
                  label="Provider Order Reference"
                  value={teetime.teeSheetOrderReference ?? "N/A"}
                />
              </Col>
              <Col xs={12} sm={4}>
                <CardRow label="Players" value={teetime.players} />
              </Col>
              <Col xs={12} sm={4}>
                <CardRow
                  label="Refundable Amount"
                  value={
                    refundableAmountFieldPreview?.newValue ??
                    teetime.refundableAmount ??
                    "N/A"
                  }
                  customValueStyles={
                    refundableAmountFieldPreview
                      ? {
                          backgroundColor: "#3dbfbe",
                          color: "white"
                        }
                      : undefined
                  }
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} sm={4}>
                <CardRow
                  label="Fee"
                  value={feeFieldPreview?.newValue ?? teetime.fee ?? "N/A"}
                  customValueStyles={
                    feeFieldPreview
                      ? {
                          backgroundColor: "#3dbfbe",
                          color: "white"
                        }
                      : undefined
                  }
                />
              </Col>
              <Col xs={12} sm={4}>
                <CardRow
                  label="Discount"
                  value={Math.abs(teetime.discountAmount) ?? 0}
                />
              </Col>
              <Col xs={12} sm={4}>
                {teetime.cancelled && (
                  <CardRow
                    label="Cancelled At"
                    value={moment(teetime.cancelledDate)
                      .utc()
                      .format("YYYY-MM-DD HH:mm")}
                  />
                )}
              </Col>
            </Row>

            <Row className="mt-3 align-items-center">
              <Col xs={12} sm={4}>
                <CardRow label="Tee" value={teetime.startingTee} />
              </Col>
              <Col xs={12} sm={4}>
                {provider === "GF365_SIMPLE" ? (
                  <CardRow
                    label="Booking Status"
                    value={BookingStatus[offlineBookingStatus]?.label || "N/A"}
                    valueClass={BookingStatus[offlineBookingStatus]?.style}
                    customValueStyles={
                      bookingStatusFieldPreview
                        ? {
                            backgroundColor: "#3dbfbe",
                            color: "white"
                          }
                        : undefined
                    }
                  />
                ) : (
                  <CardRow
                    label="Order Item ID's"
                    value={
                      teetime.bookingDetailsIds.length
                        ? teetime.bookingDetailsIds.join(", ")
                        : "N/A"
                    }
                  />
                )}
              </Col>
              <Col xs={12} sm={4}>
                {!(
                  teetime.cancelled || offlineBookingStatus === "PRE_BOOKED"
                ) ? (
                  <CardRow
                    className={`font-weight-bold m-0 ${
                      teetime.payOnSite ? "text-danger" : "text-success"
                    }`}
                    value={teetime.payOnSite ? "Pay on site" : "Paid"}
                  />
                ) : (
                  provider !== "GF365_SIMPLE" && (
                    <CardRow
                      customValueStyles={
                        bookingStatusFieldPreview
                          ? {
                              backgroundColor: "#3dbfbe",
                              color: "white"
                            }
                          : undefined
                      }
                      valueClass={BookingStatus["CANCELLED"]?.style}
                      value="Cancelled"
                    />
                  )
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={4}>
                {!teetime.cancelled && (
                  <CardRow
                    label="Cancel by"
                    value={moment
                      .utc(teetime.cancelDateTime)
                      .tz(localTimeZone)
                      .format("YYYY-MM-DD HH:mm")}
                    valueClass={`key-figure key-figure-sm mb-0 ${
                      isAfterCancelDate ? "text-muted" : ""
                    }`}
                  />
                )}
              </Col>
              <Col xs={12} md={4}>
                <ErrorLabel />
              </Col>
            </Row>
          </Col>

          <Col xs={12} sm={12} lg={2} xl={2} className="mt-4 mt-md-0">
            {teetime.invoiceId && (
              <Button
                color="primary"
                className="mb-2 w-100"
                onClick={() => {
                  const baseUrl = window.location.origin;
                  const url = `${baseUrl}/invoice/${teetime.invoiceId}`;
                  window.open(url, "_blank", "noopener,noreferrer");
                }}
              >
                Invoice
              </Button>
            )}
            <Button
              disabled={offlineBookingStatus === "PRE_BOOKED"}
              color="primary"
              className="mb-2 w-100"
              onClick={toggle}
            >
              Edit
            </Button>
            {canCancelProvider && (
              <ProviderCancellationButton
                teetimeId={teetime._id}
                clubBookingId={clubBookingId}
              />
            )}
            {canCancelInternal && (
              <InternalCancellation
                teetimeId={teetime._id}
                refundableAmount={teetime.refundableAmount || teetime.price}
                teetimePrice={teetime.price}
                payOnSite={teetime.payOnSite}
                disableRefund={offlineBookingStatus === "PRE_BOOKED"}
              />
            )}
            {provider === "GF365_SIMPLE" && (
              <HandleOfflineOrderButton
                orderStatus={offlineBookingStatus}
                bookingId={teetime.teeSheetOrderReference}
              />
            )}
            {teetime.cancelled && (
              <OrderSendEmailButton
                id={`btn-cancellation-email-${teetime._id}`}
                onClick={handleSendCancellationEmail}
                loading={loading}
                error={error}
                success={data?.sendCancellationConfirmation.success}
              >
                Send cancellation confirmation
              </OrderSendEmailButton>
            )}

            <Modal isOpen={modal} toggle={toggle} size="lg" centered>
              <ModalHeader toggle={toggle}>Edit Tee Time Details</ModalHeader>
              <ModalBody>
                <h5>Order Info</h5>
                <Row>
                  <Col xs={12} md={6}>
                    <Label for="orderTotal">Order Total</Label>
                    <Input
                      id="orderTotal"
                      type="number"
                      value={modalValues.orderTotal}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Label for="orderPayNowTotal">Order Pay Now Total</Label>
                    <Input
                      id="orderPayNowTotal"
                      type="number"
                      value={modalValues.orderPayNowTotal}
                      disabled
                    />
                  </Col>
                </Row>

                <h5 className="mt-4">Club Info</h5>
                <Row>
                  <Col xs={12} md={6}>
                    <Label for="clubTotal">Club Total</Label>
                    <Input
                      id="clubTotal"
                      type="number"
                      value={modalValues.clubTotal}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Label for="clubPayNowTotal">Club Pay Now Total</Label>
                    <Input
                      id="clubPayNowTotal"
                      type="number"
                      value={modalValues.clubPayNowTotal}
                      disabled
                    />
                  </Col>
                </Row>

                <h5 className="mt-4">Tee Time Info</h5>
                <Row>
                  <Col xs={12} md={6}>
                    <Label for="teeTimeTotal">Tee Time Total</Label>
                    <Input
                      id="teeTimeTotal"
                      type="number"
                      min={0}
                      value={modalValues.teetimePrice}
                      onChange={(e) => {
                        const highestPrice =
                          getHighestOldPrice() || teetime.price;
                        Number(e.target.value) > highestPrice
                          ? addAlert({
                              color: "danger",
                              message: `Price cannot be higher than ${highestPrice}`
                            })
                          : handleModalChange("teetimePrice", e.target.value);
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Label for="refundableAmount">Refundable Amount</Label>
                    <Input
                      id="refundableAmount"
                      type="number"
                      value={modalValues.refundableAmount}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <Label for="fee">Fees</Label>
                    <Input
                      id="fee"
                      type="number"
                      value={modalValues.fee}
                      min={0}
                      onChange={(e) => handleModalChange("fee", e.target.value)}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggleCalculatePrice}>
                  Calculate
                </Button>
                <Button
                  color="primary"
                  onClick={handleUpdateOrder}
                  disabled={loadingSavingOrder || !hasCalculated}
                >
                  {loadingSavingOrder ? "Updating..." : "Save"}
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
