import React, { useContext } from "react";
import { InvoiceContext } from "../Invoice";
import { Container, Table } from "reactstrap";
import moment from "moment-timezone";

const OrderRowTable = () => {
  const { invoice, localTimezone } = useContext(InvoiceContext);
  return (
    <Container>
      <h1>Order rows</h1>
      <Table className="mt-2" responsive>
        <thead>
          <tr>
            <th>Order ref</th>
            <th>Sell Date</th>
            <th>Play Date</th>
            <th>Rate type</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {invoice?.invoiceRows?.map((row, index) => {
            const rateTypes =
              row.ratesUsed?.length > 1
                ? row.ratesUsed.map(
                    (rate, index) =>
                      rate.rateType +
                      (index < row.ratesUsed.length - 1 ? " / " : "")
                  )
                : row.ratesUsed[0]?.rateType;
            return (
              <tr key={index}>
                <td>{invoice.name}</td>
                <td>{moment(invoice.sellDate).format("YYYY-MM-DD")}</td>
                <td>
                  {moment
                    .utc(invoice.playDate)
                    .tz(localTimezone)
                    .format("YYYY-MM-DD HH:mm")}
                </td>
                <td>{rateTypes}</td>
                <td>{row.players}</td>
                {/* Rounding to 2 decimals */}
                <td>{Math.round((row.price / row.players) * 100) / 100}</td>
                <td>{row.price}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default OrderRowTable;
