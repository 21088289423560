import React, { forwardRef } from "react";
import { Button } from "reactstrap";
import Loader from "../../ui/Loader";

const ExportExcelButton = forwardRef(({ loading, onClick, disabled }, ref) => {
  return (
    <div ref={ref}>
      {loading ? (
        <Button className="cursor-default" color="link" type="button">
          <Loader size={25} />
        </Button>
      ) : (
        <Button
          className="cursor-default"
          color="primary"
          onClick={onClick}
          disabled={disabled}
        >
          Export to Excel
        </Button>
      )}
    </div>
  );
});

export default ExportExcelButton;
