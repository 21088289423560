// Vendor
import React, { createContext, useEffect, useReducer, useState } from "react";
import { InvoiceGeneralInfo, OrderRowTable } from "./subcomponents";
import { useQuery } from "@apollo/client";
import { GET_INVOICE, INVOICE_GET_COMMENTS } from "../../common/Queries";
import Loader from "../ui/Loader";
import invoiceReducer from "./invoiceReducer";
import NoMatch from "../ui/NoMatch";
import { useHistory } from "react-router-dom";
import InvoiceComments from "./InvoiceComments";

/** @type { InvoiceContext } */
export const InvoiceContext = createContext(null);

const Invoice = (props) => {
  // getting invoice id
  const id = props.match.params.id;
  const [state, dispatch] = useReducer(invoiceReducer, null);
  const [editing, setEditing] = useState(false);
  const [initialState, setInitialState] = useState(null);
  const history = useHistory();

  // handlers
  const handleOnCompleted = (data) => {
    initStates(data.invoice.invoice);
    dispatch({ type: "LOCAL_TIMEZONE", payload: data.invoice.localTimeZone });
  };

  const initStates = (invoice) => {
    setInitialState(invoice);
    dispatch({ type: "INIT", payload: invoice });
  };

  const toggleEdit = () => {
    setEditing(!editing);
  };

  // making a reqeust with the invoice id
  /** @type {import("@apollo/client").QueryResult<InvoiceData || undefined>} */
  const { loading: invoiceLoading, error } = useQuery(GET_INVOICE, {
    variables: { id },
    onCompleted: handleOnCompleted
  });

  const { loading: commentsLoading, error: fetchCommentsError } = useQuery(
    INVOICE_GET_COMMENTS,
    {
      onCompleted: ({ getInvoiceComments }) => {
        dispatch({ type: "COMMENTS_INIT", payload: getInvoiceComments });
      },
      onError: (err) => console.error(err),
      variables: { invoiceId: id }
    }
  );

  const isSubInvoice = !!state?.referenceTo;

  // useEffect to scroll to top of page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  if (invoiceLoading) return <Loader fullscreen />;

  if (error) return <NoMatch />;

  return (
    <div className="Invoice">
      <InvoiceContext.Provider
        value={{
          initialState,
          initStates,
          invoice: state,
          dispatch,
          isSubInvoice,
          editing,
          toggleEdit,
          localTimezone: state?.localTimezone
        }}
      >
        {!commentsLoading && !fetchCommentsError && (
          <InvoiceComments comments={state?.comments ?? []} />
        )}
        <InvoiceGeneralInfo />
        {!isSubInvoice && <OrderRowTable />}
      </InvoiceContext.Provider>
    </div>
  );
};
export default Invoice;

/**
 * @typedef InvoiceData
 * @property {Object} invoice - backend endpoint response
 * @property {Invoice} invoice.invoice
 */
